/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/smoothstate@0.7.2/src/jquery.smoothState.min.js
 * - /npm/slideout@1.0.1/dist/slideout.min.js
 * - /npm/scrollreveal@3.4.0/dist/scrollreveal.min.js
 * - /npm/nprogress@0.2.0/nprogress.min.js
 * - /npm/little-loader@0.2.0/dist/little-loader.min.js
 * - /npm/bigpicture@1.3.2/dist/BigPicture.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
